<template>
 <div class="movie">
  <div class="content oepnX">
   <div v-if="tagList && tagList.length" class="tagList">
     <VerticleThreeList :videoList="tagList" v-if="kind == 0"></VerticleThreeList>
     <TransverselyTwoList class="twoBox" :videoList="tagList" v-else-if="kind == 1"></TransverselyTwoList>
     <TransverselyTwoList :videoList="tagList" v-else-if="kind == 2"></TransverselyTwoList>
     <TransverselyTwoList :videoList="tagList" v-else-if="kind == 4"></TransverselyTwoList>
     <div class="more" @click="$router.push({path:'/tagList',query: {tagName: searchText, id: tagID, kind: kind ,isAcg: 1}})">查看更多</div>
   </div>
   <van-tabs
     class="tabsList oepnX"
     type="card"
     swipeable
     animated
     line-width="24"
     title-active-color="#333333"
     title-inactive-color="rgba(153, 153, 153, 1)"
     line-height="6"
     v-model="activeName"
     v-if="navList"
     @change="clickTag"
   >
    <van-tab :title="item.label" v-for="(item, index) in navList" :key="index">
     <div class="topicPageContent">
      <PullRefresh
        v-if="!isLoading"
        :loading="loading"
        :refreshing="refreshing"
        :finished="finished"
        @onLoad="onLoad"
        @onRefresh="onRefresh"
        :isNoData="isNoData"
        :error="error"
        :is-higeht-max="true"
        :hasAdvPagination="true"
        className="Page"
      >
       <VerticleThreeList
         :showAdv="true"
         :videoList="videoList"
         v-if="kind == 0"
       >
       </VerticleThreeList>
       <TransverselyTwoList
         class="twoBox"
         :videoList="videoList"
         v-else-if="kind == 1"
       ></TransverselyTwoList>
       <TransverselyTwoList
         :videoList="videoList"
         v-else-if="kind == 2"
       ></TransverselyTwoList>
      <TransverselyTwoList
         :videoList="videoList"
         v-else-if="kind == 4"
       ></TransverselyTwoList>
      </PullRefresh>
      <Loading v-else />
     </div>
    </van-tab>
   </van-tabs>
  </div>
 </div>
</template>

<script>
import VerticleThreeList from "@/components/VideoList/VerticleThreeList";
import TransverselyTwoList from "@/components/VideoList/TransverselyTwoList";
import {AdType, getAdItem, jumpAdv, setSearchKey} from "@/utils/getConfig";
import {querySearchList, queryTopicMediaList} from "@/api/video";
import { moduleListMore } from "@/api/home";
import Loading from "@/components/Loading";
import PullRefresh from "@/components/PullRefresh";
export default {
 name: "moviePage",
 props: {
  searchText: {
   type: String,
   required: true
  },
  kind: {
   type: Number,
   required: true
  },
 },
 components: {
  VerticleThreeList,
  TransverselyTwoList,
  Loading,
  PullRefresh,
 },
 data() {
  return {
   activeName: 0,
   pageNumber: 1,
   pageSize: 12,
   topicId: "",
   zone: "",
   titleName: "",
   format: 0,
   tabNmae: "",
   name: "",
   advNum: 0,
   videoList: [],
   oldVideoList: [],
   listAdvs: [],
   listAdvsVerticel: [],
   isLoading: true,
   loading: true,
   refreshing: false,
   finished: false,
   isNoData: false,
   error: false,
   navList: [
    {
     label: '最多观看',
     value: 0,
    },
    {
     label: '最多收藏',
     value: 1,
    },
    {
     label: '最新上架',
     value: 2,
    },
   ],
   tagList: [],
   tagID: [],
  };
 },
 created() {
  this.listAdvs = getAdItem(AdType.TIPIC_MORE);
  this.listAdvsVerticel = getAdItem(AdType.TIPIC_MORE_VERTICLE);
 },
 mounted() {
  this.getSearchList("refresh");
 },
 methods: {
  async getSearchList(type) {

   this.$bus.$emit("dataBuriedEvent",{
    "dataType":2,
    tagsName: this.searchText
   });

   let req = {
    pageNumber: this.pageNumber,
    pageSize: this.pageSize,
    kind: this.kind,
    keyword: this.searchText,
    sortType: this.navList[this.activeName].value,
   }
   let res = await this.$Api(querySearchList, req);

   const {data} = res;
   this.loading = false;
   this.refreshing = false;
   this.isLoading = false;

   try {
    if(res && res.code == 200){
     const tag = data.tagMedias;
     if (tag.mediaList && tag.mediaList.length && this.pageNumber === 1) {
      // this.tagID = tag.tagID[0];
      this.tagID = [...tag.tagID];
      this.tagList = tag.mediaList.map(item => {
       const matchingTag = item.tagNames.find(tag => tag.includes(this.searchText));
       if (matchingTag) {
        return {
         ...item,
         activeTagName: matchingTag
        };
       }
       return item;
      });
     }
     console.log(data.MediaList);
     if(data.MediaList && data.MediaList.length > 0){
      if(type == "refresh"){
       this.videoList = data.MediaList;
      } else {
       this.videoList = this.videoList.concat(data.MediaList);
      }
      if (!res.data.hasNext) {
       this.finished = true;
      }
     }
    } else {
     if (this.pageNumber === 1 && this.videoList.length === 0) {
      this.isNoData = true;
     }
    }
   } catch (error) {
    this.error = true;
    this.loading = false;
   }
  },
  onRefresh() {
   this.pageNumber = 1;
   this.refreshing = true;
   this.finished = false;
   this.isNoData = false;
   // this.loading = true;
   this.getSearchList("refresh");
  },
  onLoad() {
   this.loading = true;
   if (this.error) {
    this.error = false;
   } else {
    this.pageNumber++;
   }
   this.getSearchList();
  },
  clickTag(index) {
   if (index != null) {
    this.activeName = index;
   }
   this.pageNumber = 1;
   this.finished = false;
   this.loading = true;
   this.isLoading = true;
   this.isNoData = false;
   this.getSearchList("refresh");
  },
 },
 watch: {
  $route(to, from) {
   if (to.path == "/topicPage") {
    if (to.query) {
     if (this.topicId != to.query.topicId) {
      this.topicId = to.query["topicId"];
      this.zone = to.query["zone"];
      this.titleName = to.query["name"];
      this.format = to.query["format"];
      this.activeName = 0;
      this.isLoading = true;
      this.getSearchList("refresh");
     }
    }
   }
  },
 },
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/main.scss";
.movie {
 height: 100%;
 .content {
  height: calc(100vh - 48px - 44px);
  overflow-y: auto;
  background: #f5f5f5;
  padding: 0 10px;

  .tagList{
   .more {
    padding-bottom: 16px;
    text-align: center;
    font-size: 12px;
    color: #999999;
    border-bottom: 1px solid #E6E6E6;
   }
  }

  .topicPageContent {
   //padding: 0 12px;
  }
  .twoBox {
   /deep/ .previewImg {
    height: 254px;
   }
  }
 }
 .tabsList {
  padding-top: 12px;
  @include cardTab();
 }
}
</style>
