<template>
 <div class="movie">
  <div class="content">
   <div v-if="tagList && tagList.length" class="tagList">
     <TransverselyTwoList class="twoBox" :videoList="tagList" v-if="kind == 0"></TransverselyTwoList>
     <TransverselyTwoList class="twoBox" :videoList="tagList" v-else-if="kind == 1"></TransverselyTwoList>
     <TransverselyTwoList class="list" :videoList="tagList" v-else-if="kind == 3"></TransverselyTwoList>
     <CommunityList :communityList="tagList" v-else-if="kind == 7"></CommunityList>
     <TransverselyTwoList class="twoBox" :videoList="tagList" v-else-if="kind == 4"></TransverselyTwoList>
     <Waterfall class="list" :list="tagList" v-else-if="kind == 5" :key="kind" :kind="kind"></Waterfall>
     <div class="more" @click="tagJump(searchText, tagID)">查看更多</div>
   </div>
   <van-tabs
     class="tabsList oepnX"
     type="card"
     swipeable
     animated
     line-width="24"
     title-active-color="#333333"
     title-inactive-color="rgba(153, 153, 153, 1)"
     line-height="6"
     v-model="activeName"
     v-if="navList"
     @change="clickTag"
   >
    <van-tab :title="item.label" v-for="(item, index) in navList" :key="index">
     <div class="topicPageContent">
      <PullRefresh
        v-if="!isLoading"
        :loading="loading"
        :refreshing="refreshing"
        :finished="finished"
        @onLoad="onLoad"
        @onRefresh="onRefresh"
        :isNoData="isNoData"
        :error="error"
        :hasAdvPagination="true"
        className="Page"
      >
       <Waterfall class="list" :list="videoList" v-if="kind === 0" :key="kind" :kind="kind"></Waterfall>
       <TransverselyTwoList
         class="twoBox"
         :videoList="videoList"
         v-else-if="kind == 1"
       ></TransverselyTwoList>
       <TransverselyTwoList
         class="list"
         :videoList="videoList"
         v-else-if="kind == 3"
       ></TransverselyTwoList>
       <CommunityList :communityList="communityList" v-else-if="kind == 7"></CommunityList>
      <TransverselyTwoList
         class="twoBox"
         :videoList="videoList"
         v-else-if="kind == 4"
       ></TransverselyTwoList>
       <Waterfall class="list" :list="videoList" v-else-if="kind == 5"></Waterfall>
<!--       <TransverselyTwoList-->
<!--         class="list"-->
<!--         :videoList="videoList"-->
<!--         v-else-if="kind == 5"-->
<!--         :isAcg="true"-->
<!--       ></TransverselyTwoList>-->
      </PullRefresh>
      <Loading v-else />
     </div>
    </van-tab>
   </van-tabs>
  </div>
 </div>
</template>

<script>
import VerticleThreeList from "@/components/VideoList/VerticleThreeList";
import TransverselyTwoList from "@/components/VideoList/TransverselyTwoList";
import {AdType, getAdItem, jumpAdv, setSearchKey} from "@/utils/getConfig";
import {querySearchList, querySearchPostList, queryTopicMediaList} from "@/api/video";
import { moduleListMore } from "@/api/home";
import Loading from "@/components/Loading";
import PullRefresh from "@/components/PullRefresh";
import {queryCommunityList} from "@/api/community";
import CommunityList from "@/components/community/CommunityList";
import Waterfall from "@/components/Waterfall/index.vue";
import store from "@/store";
export default {
 name: "moviePage",
 props: {
  searchText: {
   type: String,
   required: true
  },
  kind: {
   type: Number,
   required: true
  },
 },
 components: {
  Waterfall,
  // VerticleThreeList,
  TransverselyTwoList,
  Loading,
  PullRefresh,
  CommunityList
 },
 data() {
  return {
   activeName: 0,
   pageNumber: 1,
   pageSize: 12,
   topicId: "",
   zone: "",
   titleName: "",
   format: 0,
   tabNmae: "",
   name: "",
   advNum: 0,
   videoList: [],
   communityList: [],
   oldVideoList: [],
   listAdvs: [],
   listAdvsVerticel: [],
   isLoading: true,
   loading: true,
   refreshing: false,
   finished: false,
   isNoData: false,
   error: false,
   navList: [
    {
     label: '最多观看',
     value: 0,
    },
    {
     label: '最多收藏',
     value: 1,
    },
    {
     label: '最新上架',
     value: 2,
    },
   ],
   tagList: [],
   tagID: [],
   isTag: 0,
  };
 },
 created() {
  this.listAdvs = getAdItem(AdType.TIPIC_MORE);
  this.listAdvsVerticel = getAdItem(AdType.TIPIC_MORE_VERTICLE);
 },
 mounted() {
  // let query = this.$route.query;
  // if (query) {
  //  this.isTag = Number(query.isTag);
  // }
  this.getSearchList("refresh");
 },
 methods: {
  async getSearchList(type) {
   this.$bus.$emit("dataBuriedEvent",{
    "dataType":2,
    tagsName: this.searchText
   });
   let res;
   let req;
   if (this.kind === 7) {
     req = {
      pageNumber: this.pageNumber,
      pageSize: this.pageSize,
      keyWords: [this.searchText],
      realm: 'video',
      sortType: this.navList[this.activeName].value,
    }
    res = await this.$Api(querySearchPostList, req);
    console.log(res, '圈子');
   } else {
    req = {
     pageNumber: this.pageNumber,
     pageSize: this.pageSize,
     kind: this.kind,
     keyword: this.searchText,
     sortType: this.navList[this.activeName].value,
    }
    res = await this.$Api(querySearchList, req);
   }

    const {data} = res;
    this.loading = false;
    this.refreshing = false;
    this.isLoading = false;

    try {
     if(res && res.code === 200){
      if (this.kind === 7) {
       const tag = data.tagVidList;
       if (tag && tag.length && this.pageNumber === 1) {
        this.tagID = data.tagID;
        this.tagList = tag.map(item => {
         const matchingTag = item.tags.find(tag => tag.name.includes(this.searchText));
         if (matchingTag) {
          return {
           ...item,
           activeTagName: matchingTag
          };
         }
         return item;
        });
       }
       console.log(this.tagList, 'taggggggggg');
       // if (data.list === null) {
       //  this.isNoData = true;
       // }
       if(type == "refresh"){
        this.communityList = data.list;
       } else {
        this.communityList = this.communityList.concat(data.list);
       }
       if (this.pageNumber === 1 && this.communityList.length === 0) {
        this.isNoData = true;
       }
       if (!res.data.hasNext) {
        this.finished = true;
       }
      } else {
       const tag = data.tagMedias;
       if (tag.mediaList && tag.mediaList.length && this.pageNumber === 1) {
        this.tagID = [...tag.tagID];
        this.tagList = tag.mediaList.map(item => {
         const matchingTag = item.tagNames.find(tag => tag.includes(this.searchText));
         if (matchingTag) {
          return {
           ...item,
           activeTagName: matchingTag
          };
         }
         return item;
        });
       }
       console.log(data.MediaList);
       // if (data.MediaList === null) {
       //  this.isNoData = true;
       // }
       if(type == "refresh"){
        this.videoList = data.MediaList;
       } else {
        this.videoList = this.videoList.concat(data.MediaList);
       }
       if (this.pageNumber === 1 && this.videoList.length === 0) {
        this.isNoData = true;
       }
       if (!res.data.hasNext) {
        this.finished = true;
       }
      }
     }
    } catch (error) {
     this.error = true;
     this.loading = false;
    }
  },
  onRefresh() {
   this.pageNumber = 1;
   this.refreshing = true;
   this.finished = false;
   // this.isNoData = false;
   this.loading = false;
   this.getSearchList("refresh");
  },
  onLoad() {
   this.loading = true;
   if (this.error) {
    this.error = false;
   } else {
    this.pageNumber++;
   }
   this.getSearchList();
  },
  clickTag(index) {
   if (index != null) {
    this.activeName = index;
   }
   this.pageNumber = 1;
   this.finished = false;
   this.loading = true;
   this.isLoading = true;
   this.isNoData = false;
   this.getSearchList("refresh");
   this.$store.commit("app/SET_HOMEINDEX", index);
  },
  tagJump(item, id) {
   if (this.kind === 7) {
    this.$router.push({
     path: "/topicDetails",
     query: {
      id: id,
     },
    });
   } else {
    let tagInfo = {
     id: id,
     name: item,
     isAcg: 2,
    }
    this.$router.push({
     path: "/tagMore",
     query: {
      tagData: this.encodeBase64(encodeURI(JSON.stringify(tagInfo))),
      kind: this.kind,
      t: Date.now(),
     }
    })
   }
  },
  // 转base64
  encodeBase64(str) {
   let base64Str = Buffer.from(str, 'utf-8').toString('base64');
   return base64Str;
  },
 },
 watch: {
  tagList: {
   immediate: true,
   handler(newVal) {
    if (newVal.length) {
     this.$emit('showTag', true);
    } else {
     this.$emit('showTag', false);
    }
   }
  },
  $route(to, from) {
   if (to.path == "/topicPage") {
    if (to.query) {
     if (this.topicId != to.query.topicId) {
      this.topicId = to.query["topicId"];
      this.zone = to.query["zone"];
      this.titleName = to.query["name"];
      this.format = to.query["format"];
      this.activeName = 0;
      this.isLoading = true;
      this.getSearchList("refresh");
     }
    }
   }
  },
 },
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/main.scss";
.movie {
 height: 100%;
 position: relative;
 .content {
  //height: calc(100vh - 48px - 44px);
  background: transparent;
  //padding: 0 10px;

  .tagList{
   .more {
    padding-bottom: 16px;
    margin-bottom: 12px;
    text-align: center;
    font-size: 12px;
    color: #999999;
    border-bottom: 1px solid #E6E6E6;
   }
  }

  .topicPageContent {
   //padding: 0 12px;
  }
  .list {
   padding: 0 10px;
  }
  .twoBox {
   padding: 0 10px;
   /deep/ .previewImg {
    height: 254px;
   }
  }
 }
 .tabsList {
  @include cardTab();
  /deep/ .van-tabs__wrap {
   padding-left: 10px;
   padding-right: 10px;
  }
  ///deep/ .van-sticky--fixed{
  // background: #f5f5f5;
  // top: 0;
  // height: 50px;
  // padding-top: 5px;
  //}
 }
 /deep/ .communityHead {
  margin-top: 0;
  margin-bottom: 20px;
 }
}
</style>
