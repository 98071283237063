<template>
    <div class="searchResultPage">
        <div v-if="isTag" class="contentBg" :style="{ background: currentBg }"></div>
        <!-- 搜索栏 -->
        <div class="searchBar">
            <div class="backIcon" @click="$router.go(-1);">
              <SvgIcon iconClass="backUp" class="back"></SvgIcon>
            </div>
            <!-- 搜索框 -->
            <div class="searchBox">
                <img class="searchIco" src="@/assets/png/ranking.png" />
                <!-- <van-field type="search" @keydown.enter="getSearchList('refresh')" v-model="searchValue" placeholder="动漫/漫画/角色扮演 50000+" center clearable /> -->
                <form action="JavaScript:return true;" class="searchForm">
                  <van-field @keydown.enter="getSearchList('refresh')" v-model="searchValue" placeholder="动漫/漫画/角色扮演 50000+" center>
                      <template #button>
                          <div @click.stop="clearInput" v-if="searchValue">
                              <svg-icon class="closeBtn" iconClass="searchClose" />
                          </div>
                          <div v-else></div>
                      </template>
                  </van-field>
                </form>
            </div>
            <div class="searchBtn" @click.stop="search('')">
             <span>搜索</span>
            </div>
        </div>
        <div class="searchPageTab" v-if="!isLoading">
         <van-tabs class="navTabs oepnX" type="card" swipeable animated line-width="24" line-height="6" v-model="activeName"
                  background="rgba(0,0,0,0)" v-if="navList && navList.length" @change="clickTag">
            <van-tab v-for="(item,index) in navList" :key="index">
                <template #title v-if="!isTag">
                      <span class="navTabTitle" :class="{'navTabTitleActive':activeName == index}">
                          {{item.title}}
                      </span>
                </template>
                <template #title v-else>
                      <span class="isTagTitle" :class="{'isTagTitleActive':activeName == index}">
                          {{item.title}}
                      </span>
                </template>
                <div class="main overflow-y-auto my-refresh">
                 <Movie :searchText="searchValue" v-if="item.kind == 0" :kind="0" @showTag="handleChildTag" />
                 <Movie :searchText="searchValue" v-if="item.kind == 1" :kind="1" @showTag="handleChildTag" />
                 <Movie :searchText="searchValue" v-if="item.kind == 3" :kind="3" @showTag="handleChildTag" />
                 <Movie :searchText="searchValue" v-if="item.kind == 7" :kind="7" @showTag="handleChildTag" />
                 <Movie :searchText="searchValue" v-if="item.kind == 4" :kind="4" @showTag="handleChildTag" />
                 <Movie :searchText="searchValue" v-if="item.kind == 5" :kind="5" @showTag="handleChildTag" />
                 <div style="width: 100%; height: 200px"></div>
                </div>
<!--                <div class="bookshelfContent">-->
<!--                    <PullRefresh v-if="!isLoading" :loading="loading" :refreshing="refreshing" :finished="finished" @onLoad="onLoad"-->
<!--                                 @onRefresh="onRefresh" :isNoData="isNoData" :error="error" :is-higeht-max="true"-->
<!--                                 :adv-list="advList" :hasAdvPagination="true" className="HotRankingPage">-->
<!--                        &lt;!&ndash; 标签列表 &ndash;&gt;-->
<!--                        <div v-if="tagList && tagList.length" class="guess">-->
<!--                          <VerticleThreeList :isEdit="isEdit" :videoList="tagList"></VerticleThreeList>-->
<!--                          <div class="more">查看更多</div>-->
<!--                        </div>-->
<!--                        <van-tabs-->
<!--                          v-model="tabActive"-->
<!--                          class="tab"-->
<!--                          type="card"-->
<!--                          title-active-color="#333333"-->
<!--                          title-inactive-color="rgba(153, 153, 153, 1)"-->
<!--                          animated-->
<!--                          :scrollspy="false"-->
<!--                          :swipeable="false"-->
<!--                          :lazy-render="true"-->
<!--                          @change="onChange"-->
<!--                        >-->
<!--                         <van-tab :title="item.label" v-for="item in moduleSorts" :key="item.value">-->
<!--                          &lt;!&ndash; 横板 &ndash;&gt;-->
<!--                          <div v-if="activeName == 2">-->

<!--                           <div v-if="videoList && videoList.length > 0">-->
<!--                            <TransverselyTwoList :isEdit="isEdit" :videoList="videoList"></TransverselyTwoList>-->
<!--                           </div>-->

<!--                           &lt;!&ndash; 猜你想看 &ndash;&gt;-->
<!--                           <div v-if="videoHotList && videoHotList.length > 0" class="guess">-->
<!--                            <div class="guessTitle">-->
<!--                             <span>猜你想看</span>-->
<!--                            </div>-->
<!--                            <TransverselyTwoList :videoList="videoHotList"></TransverselyTwoList>-->
<!--                           </div>-->
<!--                          </div>-->
<!--                          &lt;!&ndash; 竖版 &ndash;&gt;-->
<!--                          <div v-else>-->
<!--                           <div v-if="videoList && videoList.length > 0">-->
<!--                            <VerticleThreeList :isEdit="isEdit" :videoList="videoList"></VerticleThreeList>-->
<!--                           </div>-->
<!--                          </div>-->
<!--                         </van-tab>-->
<!--                        </van-tabs>-->
<!--                        &lt;!&ndash; 猜你想看 &ndash;&gt;-->
<!--                        <div v-if="videoHotList && videoHotList.length > 0" class="guess">-->
<!--                         <div class="guessTitle">-->
<!--                          <span>猜你想看</span>-->
<!--                         </div>-->
<!--                         <VerticleThreeList :videoList="videoHotList"></VerticleThreeList>-->
<!--                        </div>-->
<!--                    </PullRefresh>-->
<!--                    <Loading v-else />-->
<!--                </div>-->
            </van-tab>
        </van-tabs>
        </div>
        <Loading v-else />
    </div>
</template>

<script>
import {queryHotInfoList, querySearchList} from "@/api/video";
import PullRefresh from "@/components/PullRefresh";
import Loading from "@/components/Loading";
import VerticleThreeList from "@/components/VideoList/VerticleThreeList";
import TransverselyTwoList from "@/components/VideoList/TransverselyTwoList";
import store from "@/store";
import {setSearchKey} from "@/utils/getConfig";
import SvgIcon from "@/components/SvgIcon";
import { Toast } from "vant";
import Movie from "./Movie.vue";
import Post from "./Post.vue";
import TabPageScroll from "@/minxs/resetTabPageScrollTop";

export default {
    name: "searchResultPage",
    components:{
     Movie,
     // Post,
        // PullRefresh,
        Loading,
        // VerticleThreeList,
        // TransverselyTwoList,
     SvgIcon
    },
    mixins: [TabPageScroll],
    data(){
        return {
            searchValue:"",
            pageNumber:1,
            pageSize:20,
            activeName:0,
            topicId:"",
            zone:"",
            titleName:"",
            videoList:[],
            videoHotList:[],
            isLoading:true,
            loading: true,
            refreshing: false,
            finished: false,
            isNoData: false,
            advList: [],
            error: false,
            delItem: null,
            navList:[
                {
                    title:"动漫",
                    kind: 0
                },
                {
                    title:"漫画",
                    kind: 1
                },
                {
                    title:"真人",
                    kind: 3
                },
                {
                    title:"圈子",
                    kind: 7
                },
                {
                    title:"小说",
                    kind: 4
                },
                {
                    title:"写真",
                    kind: 5
                },
                // {
                //     title:"原作",
                //     kind:6
                // }
            ],
            isTypeLoading : 0 ,   //书籍加载，如果加载完，就加载推荐（0：加载书籍数据，1：加载推荐数据）
            isEdit: false,
            tagList: [],
            moduleSorts: [
             {
              label: '最多观看',
              value: 0,
             },
             {
              label: '最多收藏',
              value: 1,
             },
             {
              label: '最新上架',
              value: 2,
             },
            ],
            tabActive: 0,
            isTag: 0,
            tagsBg: ['linear-gradient(359.61deg, rgba(99, 73, 64, 0) 3.98%, #C99482 99.58%)', 'linear-gradient(359.61deg, rgba(99, 73, 64, 0) 3.98%, #A6ABB4 99.58%)', 'linear-gradient(359.61deg, rgba(99, 73, 64, 0) 3.98%, #FFC6DF 99.58%)'],
            currentBg: '',
        }
    },
    watch: {
     '$route.query.searchValue'(newValue, oldValue) {
      if (newValue && oldValue) {
       this.isLoading = true;
       this.$nextTick(() => {
        this.isLoading = false;
       });
      }
     }
    },
    mounted() {
        let query = this.$route.query;
        if (query){
            this.searchValue = query.searchValue;
            this.isTag = Number(query.isTag);
            this.getSearchList('refresh');
        }
        // this.getHotInfoList('refresh');
    },methods:{
        handleChildTag(show) {
         console.log(show, '显示背景');
         this.isTag = show;
        },
        search(keyword) {
         if (!keyword && !this.searchValue) return Toast('请输入搜索内容');
         this.$router.replace({
          path: '/searchResultPage',
          query: {
           searchValue: keyword || this.searchValue,
          },
         });
        },
        clearInput() {
            this.searchValue = "";
        },
        /**
         * 获取搜索结果
         */
        async getSearchList(type) {

            if(!this.searchValue){
                return;
            }

            setSearchKey(this.searchValue);


            this.$bus.$emit("dataBuriedEvent",{
                "dataType":2,
                tagsName: this.searchValue
            });

            let req = {
                pageNumber: this.pageNumber,
                pageSize: this.pageSize,
                kind: this.navList[this.activeName].kind,
                keyword: this.searchValue,
                sortType: this.moduleSorts[this.tabActive].value,
            }
            let res = await this.$Api(querySearchList, req);

            const {data} = res;
            this.loading = false;
            this.refreshing = false;
            this.isLoading = false;

            try {
                if(res && res.code == 200){
                  const tag = data.tagMedias;
                   if (tag.mediaList && tag.mediaList.length && this.pageNumber === 1) {
                     this.tagList = tag.mediaList.map(item => {
                       const matchingTag = item.tagNames.find(tag => tag.includes(this.searchValue));
                       if (matchingTag) {
                        return {
                         ...item,
                         activeTagName: matchingTag
                        };
                       }
                       return item;
                      });
                   }
                   // console.log(this.tagList);
                    if(data.MediaList && data.MediaList.length > 0){
                        if(type == "refresh"){
                            this.videoList = data.MediaList;
                        } else {
                            this.videoList = this.videoList.concat(data.MediaList);
                        }
                    }else{
                        this.videoList = [];
                    }

                    // if (this.pageNumber === 1 && this.videoList && this.videoList.length === 0) {
                    //     this.isNoData = true;
                    // }
                    if (!data.hasNext || data.MediaList.length < req.pageSize) {
                        this.finished = true;
                        // this.isTypeLoading = 1;
                        // this.getHotInfoList('refresh');
                    }
                } else {
                    this.error = true;
                }
            } catch (error) {
                this.error = true;
                this.loading = false;
            }
        },async getHotInfoList(type) {
            /**
             * 猜你喜欢
             * @type {{pageNumber: number, kind: number, pageSize: number}}
             */
            let req = {
                pageNumber: this.pageNumber,
                pageSize: this.pageSize,
                kind: this.navList[this.activeName].kind
            }
            let res = await this.$Api(queryHotInfoList, req);
            const {data} = res;
            this.loading = false;
            this.refreshing = false;
            this.isLoading = false;

            try {
                if (res && res.code == 200) {

                    if(data.MediaList && data.MediaList.length > 0){
                        if(type == "refresh"){
                            this.videoHotList = data.MediaList;
                        }else {
                            this.videoHotList = this.videoHotList.concat(data.MediaList);
                        }
                    }

                // if (this.pageNumber === 1 && this.videoList && this.videoList.length === 0) {
                //     this.isNoData = true;
                // }
                // if (!res.data.hasNext || res.data.MediaList.length < req.pageSize) {
                //     this.finished = true;
                // }
                } else {
                    this.error = true;
                }
            } catch (error) {
                this.error = true;
                this.loading = false;
            }
        },onRefresh() {
            this.pageNumber = 1;
            this.refreshing = true;
            this.finished = false;
            this.isNoData = false;
            this.getSearchList('refresh');
            // this.getHotInfoList('refresh');
        }
        ,onLoad(){
            this.loading = true;
            if (this.error) {
                this.error = false;
            } else {
                this.pageNumber++
            }
            if(this.isTypeLoading == 0){
                // this.getSearchList();
            }else{
                // this.getHotInfoList();
            }
        },
        onChange(index) {
          this.tabActive = index;
          this.pageNumber = 1;
          this.finished = false;
          this.loading = true;
          this.isLoading = true;
          this.isNoData = false;
          this.getSearchList('refresh');
        },
        clickTag(index) {
         store.commit('app/SET_SCROLL_INFO', {
          key: this.$route.path,
          index: this.activeName,
         });
          if (this.isTag) {
           const randomIndex = Math.floor(Math.random() * this.tagsBg.length);

           this.currentBg = this.tagsBg[randomIndex];
           console.log(this.activeName, index);
          }
          // this.videoHotList = [];
          // if (this.videoHotList.length === 0) {
              // this.videoHotList = [];
              // this.activeName = index;
              //
              // this.pageNumber = 1;
              // this.finished = false;
              // this.loading = true;
              // this.isLoading = true;
              // this.isNoData = false;
              // this.getSearchList('refresh');
            // } else {
            //   Toast("数据没加载完成请等待！");
            // }
          },
          // this.videoHotList = [];
          // if(index != null){
          //     this.activeName = index;
          // }
          // this.pageNumber = 1;
          // this.finished = false;
          // this.loading = true;
          // this.isLoading = true
          // this.isNoData = false;
          // this.getSearchList('refresh');
          // this.getHotInfoList('refresh');
          // }
    }
}
</script>

<style lang="scss" scoped>
.searchResultPage{
    height: 100%;
     .contentBg {
      position: absolute;
      top: -50px;
      left: 0;
      width: 100%;
      height: 240px;
      background: linear-gradient(359.61deg, rgba(99, 73, 64, 0) 3.98%, #C99482 99.58%);
      z-index: 1;
     }
    .closeBtn {
        width: 22px;
        height: 22px;
        margin-right: 4px;
        display: inline-block;
        vertical-align: middle;
    }
    .searchForm {
        width: 100%;
    }
    .searchBar{
      position: relative;
      padding: 8px 0 9px 12px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      background: #fff;

     .backIcon {
      margin-right: 4px;
      z-index: 2;
      .back {
       width: 20px;
       height: 20px;
      }
     }

        .searchBox{
           display: flex;
           align-items: center;
           width: calc(100% - 24px - 72px);
           height: 36px;
           background: rgba(245, 245, 245, 0.5);
           border-radius: 10px;
           padding: 0 12px;
           margin-right: 12px;
           z-index: 2;

            .searchIco{
                width: 17px;
                height: 17px;
            }

            /deep/ .van-cell{
                //border-radius: 18px;
                width: 100%;
                height: 100%;
                padding: 0;
                background: transparent !important;
                margin-left: 7px;
                align-items: center;
            }

            /deep/ .van-field__control{
                color: rgb(0,0,0);
                font-size: 12px;
            }

            /deep/ .van-field__control::placeholder{
                color: rgb(153,153,153);
                font-size: 12px;
             font-weight: 400;
            }
        }

     .searchBtn{
      width: 50px;
      font-size: 16px;
      font-weight: 900;
      color: #333333;
      z-index: 2;
     }

    }

    .navTabs{

        .main {
         height: calc(100vh - 104px);
        }
        .navTabTitle{
            font-size: 15px;
            color: rgb(153,153,153);
            font-weight: 100;
        }

        .navTabTitleActive{
            font-weight: 800;
            color: #000;
        }

        .isTagTitle{
           font-size: 15px;
           color: rgba(245, 245, 245, 1);
           font-weight: 100;
        }

        .isTagTitleActive{
            font-weight: 800;
            color: #000;
        }

        /deep/ .van-tabs__nav {
            border: none;
        }

        ///deep/ .van-tabs__wrap--scrollable .van-tab{
        //    padding: 0;
        //    padding-right:21px;
        //}
        //
        /deep/ .van-tabs__wrap{
            position: relative;
            padding-top: 8px;
            height: 44px;
            z-index: 2 !important;
            // margin-bottom: 12px;
        }

        /deep/ .van-tabs__line{
          width: 18px !important;
          height: 2px !important;
          bottom: 22px !important;
          background-color: #94D6DA !important;
        }

        /deep/ .van-tab{
            padding: 0;
            width: 40px;
            border: none;
        }

        /deep/ .van-tab--active{
            border-radius: 8px;
            background: #F0F0F3 !important;
        }
        /deep/ .van-tabs__content{
         position: relative;
         z-index: 1;
        }

        .bookshelfContent{
            height: calc(100vh - 48px - 12px - 40px);
            padding: 12px 12px 0 12px;
            overflow-y: auto;
            background: #F5F5F5;

            .guess{

                .guessTitle{
                    color: rgb(0,0,0);
                    font-size: 18px;
                    font-weight: bold;
                    margin-bottom: 9px;
                }
             .more {
              padding-bottom: 16px;
              text-align: center;
              font-size: 12px;
              color: #999999;
              border-bottom: 1px solid #E6E6E6;
             }
            }
         .tab {
          padding-top: 12px;

         }
        }
    }

    .content{
        //width: 100%;
        height: calc(100% - 44px);
        padding: 0 12px;
    }

    .delDialog{

        /deep/ .van-dialog{
            background: rgb(255,255,255);
        }

        .delDialogBtn{
            width: 10.6px;
            height: 10.6px;
            position: absolute;
            right: 12px;
            top: 12px;
        }

        /deep/ .van-button--default{
            background: rgb(255,255,255);
        }

        /deep/ .van-dialog__cancel{
            color: rgb(153,153,153);
        }

        /deep/ .van-hairline--left{
            color: rgb(113,69,231);
        }

        /deep/ [class*=van-hairline]::after{
            border: 1px solid rgb(233,233,233);
        }

        .dialogContent{
            font-size: 18px;
            color: rgb(0,0,0);
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 18px 70px;
        }

    }
}
</style>
